import React, { Fragment } from 'react';
import { Typography } from '@mui/material';

export interface AcceptInviteView {
  loginEnabled: boolean;
  authRedirectUrl: string;
  loginButtonHelp: string | React.ReactElement;
  termsAccepted: boolean;
}

export const Empty: AcceptInviteView = {
  termsAccepted: false,
  loginEnabled: false,
  authRedirectUrl: '',
  loginButtonHelp: (
    <Fragment>
      <Typography color={'inherit'}>
        You must agree to the terms and conditions before accepting the invitation.
      </Typography>
    </Fragment>
  ),
};

const defaultExport = { Empty: Empty };
export default defaultExport;
